export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
    },
    /*   {
      name: 'users',
      displayName: 'menu.users',
      meta: {
        icon: 'group',
      },
    }, */
    {
      name: 'abonnements',
      displayName: 'menu.subscriptions',
      meta: {
        icon: 'folder_shared',
      },
    },
    {
      name: 'stats',
      displayName: 'menu.stats',
      meta: {
        icon: 'mso-monitoring',
      },
    },
    {
      name: 'calendar',
      displayName: 'menu.calendar',
      meta: {
        icon: 'vuestic-iconset-files',
      },
    },
    // {
    //   name: 'payments',
    //   displayName: 'menu.billing',
    //   meta: {
    //     icon: 'credit_card',
    //   },
    // },
    {
      name: 'payments',
      displayName: 'menu.payments',
      meta: {
        icon: 'credit_card',
      },
    },
  ] as INavigationRoute[],
}
